exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-barnensratt-tsx": () => import("./../../../src/pages/barnensratt.tsx" /* webpackChunkName: "component---src-pages-barnensratt-tsx" */),
  "component---src-pages-fa-hjalp-tsx": () => import("./../../../src/pages/fa-hjalp.tsx" /* webpackChunkName: "component---src-pages-fa-hjalp-tsx" */),
  "component---src-pages-gorskillnad-tsx": () => import("./../../../src/pages/gorskillnad.tsx" /* webpackChunkName: "component---src-pages-gorskillnad-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-markdown-remark-frontmatter-slug-jsx": () => import("./../../../src/pages/{markdownRemark.frontmatter__slug}.jsx" /* webpackChunkName: "component---src-pages-markdown-remark-frontmatter-slug-jsx" */),
  "component---src-pages-nyheter-tsx": () => import("./../../../src/pages/nyheter.tsx" /* webpackChunkName: "component---src-pages-nyheter-tsx" */),
  "component---src-pages-om-tsx": () => import("./../../../src/pages/om.tsx" /* webpackChunkName: "component---src-pages-om-tsx" */)
}

